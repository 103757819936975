var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { assertUnreachable } from "./helpers";
export var ErrorCode;
(function (ErrorCode) {
    ErrorCode["RequestInvalid"] = "request_invalid";
    ErrorCode["RequestUnauthorized"] = "request_unauthorized";
    ErrorCode["CredentialsInvalid"] = "credentials_invalid";
    ErrorCode["AuthorizationInvalid"] = "authorization_invalid";
    ErrorCode["OptionBadValue"] = "option_bad_value";
    ErrorCode["OptionInvalid"] = "option_invalid";
    ErrorCode["OptionCreateInvalid"] = "option_create_invalid";
    ErrorCode["OptionUpdateInvalid"] = "option_update_invalid";
    ErrorCode["OptionNotFound"] = "option_not_found";
    ErrorCode["PollInvalid"] = "poll_invalid";
    ErrorCode["PollCreateInvalid"] = "poll_create_invalid";
    ErrorCode["PollUpdateInvalid"] = "poll_update_invalid";
    ErrorCode["PollNotFound"] = "poll_not_found";
    ErrorCode["TallyInvalid"] = "tally_invalid";
    ErrorCode["VoteInvalid"] = "vote_invalid";
    ErrorCode["VoteNoOptions"] = "vote_no_options";
    ErrorCode["VoteTooManyOptions"] = "vote_too_many_options";
    ErrorCode["VoteInvalidOptions"] = "vote_invalid_options";
    ErrorCode["VoteUserAlreadyVoted"] = "vote_user_already_voted";
    ErrorCode["ImageUploadInvalid"] = "image_upload_invalid";
    ErrorCode["ImageDeleteInvalid"] = "image_delete_invalid";
    ErrorCode["BlogInvalid"] = "blog_invalid";
    ErrorCode["BlogCreateInvalid"] = "blog_create_invalid";
    ErrorCode["BlogUpdateInvalid"] = "blog_update_invalid";
    ErrorCode["BlogNotFound"] = "blog_not_found";
    ErrorCode["Unknown"] = "unknown";
})(ErrorCode || (ErrorCode = {}));
export var statusFromErrorCode = function (errorCode) {
    switch (errorCode) {
        case ErrorCode.RequestInvalid:
            return 400;
        case ErrorCode.RequestUnauthorized:
            return 401;
        case ErrorCode.CredentialsInvalid:
            return 400;
        case ErrorCode.AuthorizationInvalid:
            return 500;
        case ErrorCode.OptionBadValue:
            return 400;
        case ErrorCode.OptionInvalid:
            return 400;
        case ErrorCode.OptionCreateInvalid:
            return 400;
        case ErrorCode.OptionUpdateInvalid:
            return 400;
        case ErrorCode.OptionNotFound:
            return 404;
        case ErrorCode.PollInvalid:
            return 400;
        case ErrorCode.PollCreateInvalid:
            return 400;
        case ErrorCode.PollUpdateInvalid:
            return 400;
        case ErrorCode.PollNotFound:
            return 404;
        case ErrorCode.TallyInvalid:
            return 400;
        case ErrorCode.VoteInvalid:
            return 400;
        case ErrorCode.VoteNoOptions:
            return 400;
        case ErrorCode.VoteTooManyOptions:
            return 400;
        case ErrorCode.VoteInvalidOptions:
            return 400;
        case ErrorCode.VoteUserAlreadyVoted:
            return 400;
        case ErrorCode.ImageUploadInvalid:
            return 400;
        case ErrorCode.ImageDeleteInvalid:
            return 400;
        case ErrorCode.BlogInvalid:
            return 400;
        case ErrorCode.BlogCreateInvalid:
            return 400;
        case ErrorCode.BlogUpdateInvalid:
            return 400;
        case ErrorCode.BlogNotFound:
            return 404;
        case ErrorCode.Unknown:
            return 500;
    }
    return assertUnreachable(errorCode);
};
export var isAppErrorData = function (obj) {
    return (typeof obj === "object" &&
        obj !== null &&
        typeof obj.error === "string" &&
        Object.values(ErrorCode).includes(obj.error_code));
};
var AppError = /** @class */ (function (_super) {
    __extends(AppError, _super);
    function AppError(error, errorCode) {
        var _this = _super.call(this, error) || this;
        _this.name = "AppError";
        _this.error = error;
        _this.error_code = errorCode;
        return _this;
    }
    return AppError;
}(Error));
export { AppError };
export var isAppError = function (obj) {
    return typeof obj === "object" && obj !== null && obj.name === "AppError";
};
