export var isPoll = function (obj) {
    return (typeof obj === "object" &&
        obj !== null &&
        typeof obj.id === "string" &&
        typeof obj.name === "string" &&
        typeof obj.description === "string" &&
        (obj.selections === "single" || obj.selections === "multiple") &&
        typeof obj.created === "string" &&
        (obj.ended === null || typeof obj.ended === "string"));
};
export var isPollCreate = function (obj) {
    return (typeof obj === "object" &&
        obj !== null &&
        typeof obj.id === "string" &&
        typeof obj.name === "string" &&
        typeof obj.description === "string" &&
        (obj.selections === "single" || obj.selections === "multiple"));
};
export var isPollUpdate = function (obj) {
    return (typeof obj === "object" &&
        obj !== null &&
        (obj.name !== undefined ||
            obj.description !== undefined ||
            obj.selections !== undefined ||
            obj.ended !== undefined));
};
export var isOption = function (obj) {
    return (typeof obj === "object" &&
        obj !== null &&
        typeof obj.id === "number" &&
        typeof obj.poll_id === "string" &&
        typeof obj.text === "string" &&
        (obj.image === null || typeof obj.image === "string"));
};
export var isOptionCreate = function (obj) {
    return (typeof obj === "object" &&
        obj !== null &&
        typeof obj.text === "string" &&
        (typeof obj.image === "undefined" || typeof obj.image === "string"));
};
export var isOptionUpdate = function (obj) {
    return (typeof obj === "object" &&
        obj !== null &&
        (obj.text !== undefined || obj.image !== undefined));
};
export var isTally = function (obj) {
    return (typeof obj === "object" &&
        obj !== null &&
        typeof obj.poll_id === "string" &&
        typeof obj.option_id === "number" &&
        typeof obj.responses === "number");
};
export var isVote = function (obj) {
    return (typeof obj === "object" &&
        obj !== null &&
        Array.isArray(obj.option_ids) &&
        obj.option_ids.every(function (option_id) { return typeof option_id === "number"; }));
};
export var isBlog = function (obj) {
    return (typeof obj === "object" &&
        obj !== null &&
        typeof obj.id === "string" &&
        typeof obj.author === "string" &&
        typeof obj.content === "string" &&
        typeof obj.created === "string");
};
export var isBlogCreate = function (obj) {
    return (typeof obj === "object" &&
        obj !== null &&
        typeof obj.id === "string" &&
        typeof obj.author === "string" &&
        typeof obj.content === "string");
};
export var isBlogUpdate = function (obj) {
    return (typeof obj === "object" &&
        obj !== null &&
        (obj.author !== undefined || obj.content !== undefined));
};
export var isCredentials = function (obj) {
    return (typeof obj === "object" &&
        obj !== null &&
        typeof obj.username === "string" &&
        typeof obj.password === "string");
};
export var isAuthorization = function (obj) {
    return (typeof obj === "object" && obj !== null && typeof obj.token === "string");
};
